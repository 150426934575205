import React, { useState, useEffect, useRef } from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import HeaderLogo from "../../components/headerLogo/headerLogo"
import HeaderBack from "../../components/headerBack/headerBack"
import HeaderCart from "../../components/headerCart/headerCart"

import ProductV2 from "../../components/product/productV2"

function ProductShopify() {

    const shopifyProductId = '7104105218215'
    const base64ProductId = Buffer.from('gid://shopify/Product/' + shopifyProductId).toString('base64')

    const sizes = {
        "HALF WAIST WIDTH": [
            "",
            "13¼/34",
            "14/36",
            "15/38",
            "15½/40",
            "16½/42",
            "17¼/44",
            "",
            ""
        ],
        "HALF HIPS WIDTH": [
            "",
            "18¾/48",
            "19½/50",
            "20½/52",
            "21¼/54",
            "22/56",
            "22¾/58",
            "",
            ""
        ],
        "INSIDE LEG LENGTH": [
            "",
            "30/76",
            "30/76,5",
            "30¼/77",
            "30½/77,5",
            "30¾/78",
            "31/78,5",
            "",
            ""
        ]
    }


    return (
        <Layout>
            <SEO title="Shop - Lawton Sweatpant" />

            <ProductV2 productId={base64ProductId} sizes={sizes} />
        </Layout>
    )

} export default ProductShopify